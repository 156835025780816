import React, { useEffect, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

import { getCategoryListAction, addItemAction, getAttributebyidListAction, getexpertlistAction, getUsersListAction, getUsdcPrice } from "../Action/action";
// let bigInt = require("big-integer");

const ItemAdd = () => {
  const [form, setForm] = useState({
    category_id: "", // set default blank
    expert_id: "",
    name: "",
    lable: "",
    lable_en: "",
    item_put_on: "1",
    image: "",
    previewImage: "./images/noimage.png",
    gallery: [],
    description: "",
    description_en: "",
    share_quantity: "",
    paymentToken: config.PaymentToken,
    priceInToken: 0,
    tokenName: "",
    tokenSymbol: "",
    tokenDecimal: "",
    last_month_price: "",
    last_month_per: "",
    share_price: "",
    tag: "2",
    tagCustom: "",
    tagCustom_en: "",
    sell_end_date: "",
    last_year_perf: "",
    market_value: "",
    value: [],
    user_email: "",
    user_shares: 0,
    last_performance_value: 0,
    last_performance_period: "one_year",
    pdf: "",
    pdfPreview: "",
    pdfTitle: "",
    market_price: 0,
    purchase_price: "",
    purchase_source: "",
  });

  const [categoryList, setCategoryList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [validatioError, setvalidatioError] = useState({});
  const [saving, setsaving] = useState(false);
  const [expertlist, setexpertlist] = useState([]);
  const [maticToEur, setMaticPrice] = useState(0);
  const [usdInEur, setUsdInEur] = useState(1);
  const [usersList, setUsersList] = useState([]);

  const getUsersList = useCallback(async () => {
    let res = await getUsersListAction(form);
    if (res.success) {
      let selectList = res.data.map((el) => {
        return {
          value: el.email,
          label: el.email,
          target: {
            name: "user_email",
            value: el.email,
          },
        };
      });
      setUsersList(selectList);
    }
  }, [form]);

  const getMATICTOEUR = useCallback(async () => {
    const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=MATICEUR`);
    let maticPrice = parseFloat(response.data.price).toFixed(6);
    setMaticPrice(maticPrice);
  }, []);

  const loadUsdcPrice = useCallback(async () => {
    let usdcPrice = await getUsdcPrice();
    setUsdInEur(usdcPrice.data.value);
  }, []);

  const getcategory = useCallback(async () => {
    let res = await getCategoryListAction();
    if (res.success) {
      setCategoryList(res.data);
    }
  }, []);

  const getexpert = useCallback(async () => {
    let res = await getexpertlistAction();
    if (res.success) {
      setexpertlist(res.data);
    }
  }, []);

  const onPdfChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let pdf = event.target.files[0];
      setForm((old) => {
        return { ...old, pdf: pdf, pdfPreview: "" };
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getcategory();
        await getexpert();
        await getMATICTOEUR();
        await getUsersList();
        await loadUsdcPrice();
        // await getEURTOUSDT();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [getUsersList, getcategory, getexpert, getMATICTOEUR, loadUsdcPrice]);

  // const getEURTOUSDT = async () => {
  // 	await axios({
  // 		method: 'get',
  // 		url: `https://api.binance.com/api/v3/ticker/price?symbol=EURUSDT`,
  // 	}).then(response => {
  // 		let usdtPrice = parseFloat(response.data.price).toFixed(6);
  // 		seteurToUsdt(usdtPrice);
  // 	})
  // }

  const getattributesByid = async (id) => {
    let res = await getAttributebyidListAction({ category_id: id });
    if (res.success) {
      setAttributesList(res.data);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;

    if (name === "share_quantity") {
      setForm({ ...form, [name]: value, market_value: value * form.share_price });
    } else if (name === "share_price") {
      setForm({ ...form, [name]: value, market_value: form.share_quantity * value });
    } else {
      setForm({ ...form, [name]: value });
    }
    if (e.target.name === "category_id") {
      getattributesByid(e.target.value);
    }
  };

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setForm((old) => {
        return { ...old, image: img, previewImage: URL.createObjectURL(img) };
      });
    }
  };

  const onImageGalleryChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let gallery = [...form.gallery];
      gallery[index] = {
        image: img,
        previewImage: URL.createObjectURL(img),
      };
      setForm((old) => {
        return { ...old, gallery: gallery };
      });
    }
  };

  function validate() {
    let category_nameError = "";
    let expert_nameError = "";
    let nameError = "";
    let lableError = "";
    let imageError = "";
    let descriptionError = "";
    let share_quantityError = "";
    let share_priceError = "";
    let tagError = "";
    let sell_end_dateError = "";
    if (form.category_id === "") {
      category_nameError = "Category is required.";
    }
    if (form.id === "") {
      expert_nameError = "Expert name is required.";
    }
    if (form.name === "") {
      nameError = "Name is required.";
    }
    if (form.lable === "") {
      lableError = "Lable is required.";
    }
    if (form.image === "") {
      imageError = "Image is required.";
    }
    if (form.description === "") {
      descriptionError = "Description is required.";
    }
    if (form.description_en === "") {
      descriptionError = "Description (EN) is required.";
    }
    if (form.share_quantity === "") {
      share_quantityError = "Share quantity is required.";
    }
    if (form.share_price === "") {
      share_priceError = "Price is required.";
    }
    if (form.tag === "" || (form.tag === "0" && form.tagCustom === "")) {
      console.log("Tag is required.");
      tagError = "Tag is required.";
    }
    if (form.sell_end_date === "") {
      sell_end_dateError = "Sell end date is required.";
    }

    if (
      category_nameError ||
      expert_nameError ||
      nameError ||
      lableError ||
      imageError ||
      descriptionError ||
      share_quantityError ||
      share_priceError ||
      tagError ||
      sell_end_dateError
    ) {
      setvalidatioError({
        category_nameError,
        expert_nameError,
        nameError,
        lableError,
        imageError,
        descriptionError,
        share_quantityError,
        share_priceError,
        tagError,
        sell_end_dateError,
      });
      return false;
    } else {
      setvalidatioError({
        category_nameError,
        nameError,
        lableError,
        imageError,
        descriptionError,
        share_quantityError,
        share_priceError,
        tagError,
        sell_end_dateError,
      });
      return true;
    }
  }

  const processingAlert = async () => {
    let timerInterval;
    Swal.fire({
      title: '<span style="color:red;">Please Wait</span>',
      html: "<b>Processing transaction...</b></br><p>Please do not refresh or close the window</p>",
      timer: 1000000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Transaction done!");
      }
    });
  };

  const closeProcessingAlert = async () => {
    Swal.close();
  };

  const imageUpload = async () => {
    let formData1 = new FormData();
    formData1.append("file", form.image);
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    let resIPF = await axios.post(url, formData1, {
      headers: {
        "Content-Type": `multipart/form-data; boundary= ${formData1._boundary}`,
        pinata_api_key: "b5149f3bd2763b83d7fb",
        pinata_secret_api_key: "30b5d1de54b60b82c314d8a61486c6ad6a2f1bc6c2881deac21180f17df64021",
      },
    });
    console.log(resIPF.data);
    let ipfsImg = resIPF.data.IpfsHash;
    let data = {
      name: form.name,
      description: form.description,
      image: `ipfs://${ipfsImg}`,
    };
    let jsonData = JSON.stringify(data);
    let formData2 = new FormData();
    formData2.append("file", new Blob([jsonData], { type: "application/json" }));
    let resMetadataIPFS = await axios.post(url, formData2, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData2._boundary}`,
        pinata_api_key: "b5149f3bd2763b83d7fb",
        pinata_secret_api_key: "30b5d1de54b60b82c314d8a61486c6ad6a2f1bc6c2881deac21180f17df64021",
      },
    });
    let ipfsMetadataHash = resMetadataIPFS.data.IpfsHash;
    console.log(`Metadata uploaded to IPFS with hash: ${ipfsMetadataHash}`);
    return ipfsMetadataHash;
    // await setItemUri();
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
      return;
    }

    await loadUsdcPrice();

    try {
      processingAlert();

      let ipfsImg = await imageUpload();

      const sharePriceBig = new BigNumber(form.share_price);
      const usdInEurBig = new BigNumber(usdInEur);
      const shareQuantityBig = new BigNumber(form.share_quantity);
      const share_usdc_price = sharePriceBig.multipliedBy(usdInEurBig).multipliedBy(10 ** 6);
      const marketValue = shareQuantityBig.multipliedBy(sharePriceBig);
      const minimumTarget = shareQuantityBig.multipliedBy(share_usdc_price).toFixed(0, BigNumber.ROUND_DOWN);

      form.usdc_price = usdInEur;
      form.market_value = marketValue.toString();

      console.log(parseInt(new Date(form.sell_end_date).valueOf() / 1000));
      console.log(parseInt(new Date(new Date(form.sell_end_date).setMonth(new Date(form.sell_end_date).getMonth() + 2)).valueOf() / 1000));

      let trx_data = {
        uri: `ipfs://${ipfsImg}`,
        maxShares: parseInt(form.share_quantity),
        preSaleStart: parseInt(new Date().getTime() / 1000),
        preSaleEnd: parseInt(new Date(new Date(form.sell_end_date).setMonth(new Date(form.sell_end_date).getMonth() + 2)).valueOf() / 1000),
        minimumTarget: minimumTarget.toString(),
        sharePrice: share_usdc_price.toFixed(0),
        paymentToken: form.paymentToken,
      };

      form.trx_data = JSON.stringify(trx_data);

      let atData = [];
      $(".attr").each(function (index) {
        let b = {
          attribute_id: $(this).attr("data-attr_id"),
          value: $(this).val(),
        };
        atData.push(b);
      });
      form.attribute_data = JSON.stringify(atData);

      let formData = new FormData();

      Object.keys(form).forEach((key) => {
        if (key === "gallery") {
          form[key].forEach((item) => {
            if (item.image && item.image instanceof File) {
              formData.append("gallery", item.image);
            }
          });
        } else if (key === "image" && form[key] instanceof File) {
          formData.append("image", form[key]);
        } else {
          formData.append(key, form[key]);
        }
      });

      let res = await addItemAction(formData);

      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setsaving(false);
        setTimeout(() => {
          window.location.href = `${config.baseUrl}itemList`;
        }, 1200);
      } else {
        closeProcessingAlert();
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      console.error(err);
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transaction rejected!");
      } else {
        toast.error("Transaction error: " + err.message);
      }
      return false;
    }
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Add New Item</h4>
                      <a className="text-primary" href={`${config.baseUrl}itemList`}>
                        <i className="fa fa-arrow-left"></i> Back
                      </a>
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Category</label>
                            <div className="col-md-12">
                              <select className="form-control" name="category_id" value={form.category_id} onChange={inputHandler}>
                                <option value="">-Select Category-</option>
                                {categoryList.map((item) => (
                                  <option value={item.id}>{item.category_name}</option>
                                ))}
                              </select>
                            </div>
                            <span className="validationErr">{validatioError.category_nameError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Expert List</label>
                            <div className="col-md-12">
                              <select className="form-control" name="expert_id" value={form.expert_id} onChange={inputHandler1}>
                                <option value="">-Select Expert List-</option>
                                {expertlist.map((item) => (
                                  <option value={item.id}>{item.expert_name}</option>
                                ))}
                              </select>
                            </div>
                            <span className="validationErr">{validatioError.expert_nameError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Name</label>
                            <div className="col-md-12">
                              <input className="form-control" type="text" autoComplete="off" name="name" value={form.name} onChange={inputHandler} placeholder="Item Name" />
                            </div>
                            <span className="validationErr">{validatioError.nameError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Lable</label>
                            <div className="col-md-12">
                              <input className="form-control" type="text" autoComplete="off" name="lable" value={form.lable} onChange={inputHandler} placeholder="Lable" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Lable (EN)</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="lable_en"
                                value={form.lable_en}
                                onChange={inputHandler}
                                placeholder="Lable (EN)"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-2" />
                        <div className="col-md-4" />

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Tag</label>
                            <div className="col-md-12">
                              <select className="form-control" name="tag" value={form.tag} onChange={inputHandler}>
                                <option value={0}>Custom Tag</option>
                                <option value={1}>Best Seller</option>
                                <option value={2} selected>
                                  New Item
                                </option>
                                <option value={3}>Holding Period 1 year</option>
                                <option value={4}>Holding Period 2 years</option>
                                <option value={5}>Holding Period 3 years</option>
                                <option value={6}>Holding Period 4 years</option>
                                <option value={7}>Holding Period 5 years</option>
                              </select>
                            </div>
                          </div>
                          {(form.tag !== 0 || form.tag !== "0") && <span className="validationErr">{validatioError.tagError}</span>}
                        </div>
                        <div className="col-md-2" />
                      </div>
                      {(form.tag === 0 || form.tag === "0") && (
                        <>
                          <div className="row">
                            <div className="col-md-6" />
                            <div className="col-md-4">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Custom Tag</label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="off"
                                    name="tagCustom"
                                    value={form.tagCustom}
                                    onChange={inputHandler}
                                    placeholder="Custom Tag"
                                  />
                                </div>
                              </div>
                              <span className="validationErr">{validatioError.tagError}</span>
                            </div>
                            <div className="col-md-2" />
                          </div>
                          <div className="row">
                            <div className="col-md-6" />
                            <div className="col-md-4">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Custom Tag (EN)</label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="off"
                                    name="tagCustom_en"
                                    value={form.tagCustom_en}
                                    onChange={inputHandler}
                                    placeholder="Custom Tag (EN)"
                                  />
                                </div>
                              </div>
                              <span className="validationErr">{validatioError.tagError}</span>
                            </div>
                            <div className="col-md-2" />
                          </div>
                        </>
                      )}
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">ItemPutOn</label>
                            <div className="col-md-12">
                              <select className="form-control" name="item_put_on" value={form.item_put_on} onChange={inputHandler}>
                                <option value={1}>Pre-order</option>
                                {/* <option value={2}>Marketplace</option> */}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Sell End Date</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="date"
                                autoComplete="off"
                                name="sell_end_date"
                                value={form.sell_end_date}
                                onChange={inputHandler}
                                placeholder="Sell end date"
                              />
                            </div>
                            <span className="validationErr">{validatioError.sell_end_dateError}</span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Image</label>
                            <div className="col-md-12">
                              <input className="form-control mb-2" type="file" name="image" onChange={onImageChange} />
                              <img alt="" src={form.previewImage} width="100px" height="100px"></img>
                            </div>
                            <span className="validationErr">{validatioError.imageError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      {form.gallery.map((val, key) => (
                        <div className="row" key={key}>
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Image</label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" name="image" onChange={(e) => onImageGalleryChange(e, key)} />
                                <img alt="" src={form.gallery[key].previewImage ?? ""} width="100px" height="100px"></img>
                              </div>
                              <span className="validationErr">{validatioError.imageError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <button
                              style={{ maxWidth: "fit-content" }}
                              className="btn btn-danger"
                              onClick={() => {
                                let gallery = form.gallery;
                                gallery = gallery.filter((el) => el !== form.gallery[key]);
                                setForm({
                                  ...form,
                                  gallery: gallery,
                                });
                              }}
                            >
                              Remove image
                            </button>
                          </div>
                        </div>
                      ))}
                      <button
                        style={{ maxWidth: "fit-content", margin: "0 auto" }}
                        className="btn btn-primary"
                        onClick={() =>
                          setForm({
                            ...form,
                            gallery: [
                              ...form.gallery,
                              {
                                previewImage: "./images/noimage.png",
                                image: "",
                              },
                            ],
                          })
                        }
                      >
                        Add image
                      </button>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Fact sheet (pdf)</label>
                            <div className="col-md-12">
                              <input className="form-control mb-2" type="file" name="pdf" onChange={onPdfChange} />
                              {form.pdfPreview && (
                                <a className=" btn btn-primary" rel="noreferrer" target="_blank" href={form.pdfPreview}>
                                  Go to pdf
                                </a>
                              )}
                              <label className="col-form-label col-md-12">Fact sheet title</label>
                              <input
                                className="form-control mb-2"
                                type="text"
                                name="pdfTitle"
                                value={form.pdfTitle}
                                onChange={(e) => setForm({ ...form, pdfTitle: e.target.value })}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Short Description</label>
                            <div className="col-md-12">
                              <textarea className="form-control" value={form.description} name="description" onChange={inputHandler} placeholder="Description"></textarea>
                            </div>
                            <span className="validationErr">{validatioError.descriptionError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Short Description (EN)</label>
                            <div className="col-md-12">
                              <textarea
                                className="form-control"
                                value={form.description_en}
                                name="description_en"
                                onChange={inputHandler}
                                placeholder="Description (EN)"
                              ></textarea>
                            </div>
                            <span className="validationErr">{validatioError.descriptionError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">User email</label>
                            <div className="col-md-12">
                              <Select name="user_email" options={usersList} onChange={inputHandler} />
                            </div>
                            <span className="validationErr">{validatioError.userEmailError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">User shares</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="user_shares"
                                value={form.user_shares}
                                onChange={inputHandler}
                                placeholder="Enter owner shares"
                              />{" "}
                            </div>
                            <span className="validationErr">{validatioError.userEmailError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Month Price</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="last_month_price"
                                value={form.last_month_price}
                                onChange={inputHandler}
                                placeholder="Enter last month price"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Month Variation (%)</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="last_month_per"
                                value={form.last_month_per}
                                onChange={inputHandler}
                                placeholder="Enter last month variation (%)"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Share Quantity</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="share_quantity"
                                value={form.share_quantity}
                                onChange={inputHandler}
                                placeholder="Share Quantity"
                              />
                            </div>
                            <span className="validationErr">{validatioError.share_quantityError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Share Price {`( Matic Price : ${parseFloat(form.share_price / maticToEur).toFixed(6)} )`}</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="share_price"
                                value={form.share_price}
                                onChange={inputHandler}
                                placeholder="Share Price €"
                              />
                            </div>
                            <span className="validationErr">{validatioError.share_priceError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Perf. Value %</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="last_performance_value"
                                value={form.last_performance_value}
                                onChange={inputHandler}
                                placeholder="Last perf."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Perf. period</label>
                            <div className="col-md-12">
                              <select className="form-control" name="last_performance_period" value={form.last_performance_period} onChange={inputHandler}>
                                <option value="">Select Period</option>
                                <option value="one_month">1 Month</option>
                                <option value="two_month">2 Months</option>
                                <option value="three_month">3 Months</option>
                                <option value="six_month">6 Months</option>
                                <option value="one_year">1 Year</option>
                                <option value="two_year">2 Years</option>
                                <option value="three_year">3 Years</option>
                                <option value="four_year">4 Years</option>
                                <option value="five_year">5 Years</option>
                                <option value="six_year">6 Years</option>
                                <option value="seven_year">7 Years</option>
                                <option value="eight_year">8 Years</option>
                                <option value="nine_year">9 Years</option>
                                <option value="ten_year">10 Years</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-2" />

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Market Value</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="market_value"
                                value={form.market_value}
                                onChange={inputHandler}
                                placeholder="Market value"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Real Market Price</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="market_price"
                                value={form.market_price}
                                onChange={inputHandler}
                                placeholder="Market price"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Prezzo di acquisto</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="purchase_price"
                                value={form.purchase_price}
                                onChange={inputHandler}
                                placeholder="Prezzo di acquisto"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Fonte di acquisto</label>
                            <div className="col-md-12">
                              <select className="form-control" name="purchase_source" value={form.purchase_source} onChange={inputHandler}>
                                <option value="">Seleziona fonte di acquisto</option>
                                <option value="Privato">Privato</option>
                                <option value="Impresa Italia">Impresa Italia</option>
                                <option value="Impresa Estero">Impresa Estero</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      {/* <div className="row">
												<div className="col-md-2"/>
												<div className="col-md-8">
													<div className="form-group row mb-1">
														{attributesList.map((item, index) => (
															<>

																<label className="col-form-label col-md-12">
																	{item.attribute_name}
																</label>
																<div className="col-md-12">
																	<input
																		className="form-control attr"
																		key={index}
																		type="text" autoComplete="off"
																		data-attr_id={item.id}
																		// onChange={(e)=>inputHandlerAttr(item.id, e.target.value)}
																		placeholder={`Enter ${item.attribute_name}`}
																	/>
																</div>
																<span className="validationErr">
																	{validatioError.brandError}
																</span>
															</>
														))}
													</div>
												</div>
											</div> */}

                      <div className="row" style={{ display: "none" }}>
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Receive Token</label>
                            <div className="col-md-12">
                              <select className="form-control" name="paymentToken" onChange={inputHandler} value={form.paymentToken}>
                                <option value={config.ZERO_ADDRESS}>MATIC - {config.ZERO_ADDRESS} </option>
                                <option value={config.PaymentToken}>USDT - {config.PaymentToken}</option>
                                <option value={config.PaymentToken2}>USDC - {config.PaymentToken2}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="text-center">
                        <br />
                        {/* <button
														type="submit"
														className="btn btn-primary"
														onClick={submitForm}
													>
														Save
													</button> */}

                        {!saving ? (
                          <button type="submit" className="btn btn-primary" onClick={submitForm}>
                            Save
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary" disabled>
                            Please wait <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default ItemAdd;
