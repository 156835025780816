import React, { useEffect, useState } from "react";
// import config from '../coreFIles/config'
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import ReactDatatable from "@ashvin27/react-datatable";
import { deleteItemAction, getItemListAction, updateItemOnboardingAction, updateItemFractionalizeAction, refundItemAction, liquidateItem } from "../Action/action";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
// import moment from "moment";
import Web3 from "web3";

const Items = () => {
  const [getItemlist, setItemList] = useState({});
  const [allItems, setAllItems] = useState({});
  const [form] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState({});
  const [saving, setSaving] = useState(false);
  const [sellPrice, setSellPrice] = useState(0);
  const [error, setError] = useState(false);
  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    let res = await getItemListAction();
    if (res.success) {
      setItemList(res.data);
      setAllItems(res.data);
    }
  };

  const processingAlert = async () => {
    let timerInterval;
    Swal.fire({
      title: '<span style="color:red;">Please Wait</span>',
      html: "<b>Transaction is being process...</b></br><p>Please do not close the window</p>",
      timer: 1000000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Transaction done!");
      }
    });
  };

  const closeProcessingAlert = async () => {
    Swal.close();
  };

  const sellItem = async (e) => {
    e.preventDefault();
    try {
      console.log(item.id);
      setError(false);
      setSaving(true);

      await liquidateItem(item.id, { amount: sellPrice });
      Swal.fire({
        title: "Item sold!",
        text: "Item sold successfully!",
        icon: "success",
      });
    } catch (error) {
      setError(true);
      Swal.fire({
        title: "Error!",
        text: "Item sold failed!",
        icon: "error",
      });
    }
    setSaving(false);
    setShowModal(false);
    setItem({});
    setSellPrice(0);

    getItems();
  };

  const onboardItem = async (item) => {
    processingAlert();
    try {
      let res = await updateItemOnboardingAction({ item_id: item.id });
      console.log(res);
      // Simulate a delay of 10 seconds
      await new Promise((resolve) => setTimeout(resolve, 20000));
      toast.success("Item onboarded successfully");
    } catch (err) {
      toast.error("Transaction reverted: " + err.toString().split("execution reverted:")[1]?.toString().split('"')[0]);
      return false;
    } finally {
      closeProcessingAlert();
      // Reload the page after onboarding
      getItems();
    }
  };

  const downloadCsv = async () => {
    const response = await getItemListAction();
    console.log(response);
    if (response.success) {
      const csvData = [
        ["ID", "Category", "Name", "Description", "Share Quantity", "Share Price", "Market Value", "Purchase Price", "Purchase Source"],
        ...response.data.map((item) => [
          item.id,
          item.category_name,
          `"${item.name}"`,
          `"${item.description}"`,
          item.share_quantity,
          `"${item.share_price?.toString().replace(".", ",")}"`,
          `"${item.market_value?.toString().replace(".", ",") ?? "N/A"}"`,
          `"${item.purchase_price?.toString().replace(".", ",") ?? "N/A"}"`,
          `"${item.purchase_source ?? "N/A"}"`,
        ]),
      ];

      const csvString = csvData.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "items_list.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      console.error("Failed to retrieve daily transactions");
    }
  };

  const columns = [
    {
      key: "ID",
      text: "ID",
      cell: (item) => item.id,
    },
    {
      key: "category_name",
      text: "Categorys",
      cell: (item) => {
        return `${item.category_name}`;
      },
    },
    {
      key: "name",
      text: "Name",
      cell: (item) => {
        return `${item.name}`;
      },
    },
    {
      key: "Image",
      text: "Image",
      cell: (item) => {
        return (
          <>
            <a target="__blank" href={`${config.imageUrl}` + item.image}>
              <img src={`${config.imageUrl}` + item.image} width="70px" height="70px" alt=""></img>
            </a>
          </>
        );
      },
    },
    {
      key: "Share",
      text: "Details",
      cell: (item) => {
        return (
          <>
            <b>Total Share : </b>
            {item.share_quantity}
            <br />
            <b>Price : </b>€ {item.share_price}/share
            <br />
            <b>Last Year Perf : </b>
            {item.last_year_perf}%<br />
            <b>Market Value : </b>€ {item.market_value}
            <br />
            <b>Last Month Price : </b>€ {item.last_month_price}
          </>
        );
      },
    },
    {
      key: "start_price",
      text: "Initial Price",
      cell: (item) => {
        return <>{item.share_price * item.share_quantity}€</>;
      },
    },
    {
      key: "market_price",
      text: "Market Price",
      cell: (item) => {
        return <>{Math.round(item.market_price)}€</>;
      },
    },
    {
      key: "onboard",
      text: "Onboard",
      cell: (item) => {
        return (
          <>
            {parseInt(item.collected_share) === parseInt(item.share_quantity) && item.onboarded === 0 && item.fractionalize === 0 ? (
              <button type="button" className="btn btn-sm btn-default" id="editbtnid" onClick={() => onboardItem(item)}>
                Onboard
              </button>
            ) : (
              ""
            )}
            {item.onboarded === 1 && item.fractionalize === 0 ? "Fractionalizing..." : ""}
            {item.onboarded === 1 && item.fractionalize === 1 ? (
              <a target="__blank" href={`${config.blockchainUrl}tx/` + item.fractionalizeHash}>
                <i className="fa fa-check"> Onboarded & Fractionalized</i>
              </a>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      key: "sell",
      text: "Sell",
      cell: (item) => {
        return (
          <>
            {item.item_put_on == 3 && <span className="badge bg-success">Sold</span>}
            {item.onboarded === 1 && item.fractionalize === 1 && item.item_put_on != 3 ? (
              <button
                type="button"
                className="btn btn-sm btn-default"
                id="editbtnid"
                onClick={() => {
                  setItem(item);
                  setShowModal(true);
                }}
              >
                Sell item
              </button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      cell: (item) => {
        return (
          <>
            <a href={`${config.baseUrl}itemUpdate/${item.id}`} className="btn btn-sm btn-primary" id="editbtnid">
              Edit
            </a>
            <br />
            <br />
            {!item.onboarded && (
              <button type="button" className="btn btn-sm btn-default" id="editbtnid" onClick={() => refundItem(item.id)}>
                Refund
              </button>
            )}
            &nbsp;
          </>
        );
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };

  const deleteItem = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteItemAction({ item_id: id });
        if (res.success) {
          getItems();
          // toast.success(res.msg);
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
          // toast.error(res.msg);
        }
      }
    });
  };

  const refundItem = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to refund this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Refund it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await refundItemAction({ item_id: id });
        if (res.success) {
          getItems();
          // toast.success(res.msg);
          Swal.fire("Refunded!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
          // toast.error(res.msg);
        }
      }
    });
  };

  const handleActionChange = (value) => {
    switch (value) {
      case "primary":
        setItemList(allItems.filter((item) => item.onboarded === 0 && item.fractionalize === 0));
        break;
      case "secondary":
        setItemList(allItems.filter((item) => item.onboarded === 1 && item.fractionalize === 1));
        break;
      case "all":
      default:
        getItems();
        break;
    }
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Items List</h4>
                      <button type="button" className="btn btn-sm btn-primary add_btn" onClick={downloadCsv}>
                        Download CSV
                      </button>
                      <a href={`${config.baseUrl}itemAdd `} className="btn btn-sm btn-primary add_btn">
                        Add New
                      </a>
                    </div>
                    <div className="box-body">
                      <div className="form-group">
                        <label htmlFor="itemActionSelect" style={{ marginBottom: "10px" }}>
                          Market type:
                        </label>
                        <select style={{ width: "200px" }} className="form-control" id="itemActionSelect" onChange={(e) => handleActionChange(e.target.value)}>
                          <option value="all" defaultChecked>
                            All
                          </option>
                          <option value="primary">Primary</option>
                          <option value="secondary">Secondary</option>
                        </select>
                      </div>
                      <ReactDatatable config={configForTable} records={getItemlist} columns={columns} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
            {/* /.content */}
            {/*Sell item Modal */}
            <div className="modal " id="liquidityModal" aria-labelledby="liquidityModalLabel" style={{ display: showModal ? "block" : "none" }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  {/* await items is not empty */}
                  {Object.keys(item).length > 0 ? (
                    <>
                      <div className="modal-header" data-bs-theme="dark">
                        <h5 className="modal-title" id="liquidityModalLabel">
                          Sell item
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setShowModal(false);
                            setError(false);
                          }}
                        />
                      </div>
                      {error ? (
                        <div className="modal-body">
                          <div className="container">
                            <div className="mb-3">
                              <p className="text-center">Something went wrong. Please try again later</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <form onSubmit={(e) => sellItem(e)}>
                          <div className="modal-body">
                            <div className="container">
                              <div className="mb-4 d-flex align-items-center">
                                {/** Liquidity Details some p whit some data */}
                                <img src={`${config.imageUrl}` + item.image} width="70px" height="70px" alt=""></img>
                                <div className="p-3">
                                  <p>
                                    <strong>Item:</strong> {item.name}
                                  </p>
                                  <p>
                                    <strong>Category:</strong> {item.category_name}
                                  </p>
                                  <p>
                                    <strong>Shares:</strong> {item.share_quantity}
                                  </p>
                                  <p>
                                    <strong>Price per share:</strong> {sellPrice / item.share_quantity}
                                  </p>
                                </div>
                              </div>

                              <div className="mt-4 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                  Sell price (€)
                                </label>
                                <input
                                  type="number"
                                  step=".01"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter sell price"
                                  onChange={(e) => setSellPrice(e.target.value)}
                                  name="sell_price"
                                />
                                {/*                                   {validatioError.lqiAmt && <span className="validationErr mt-1">Liquidity amount error</span>}
                                 */}
                              </div>

                              <div className="modal-footer mt-20">
                                {!saving ? (
                                  <button type="submit" className="btn btn-primary">
                                    Set sell price
                                  </button>
                                ) : (
                                  <button type="submit" className="btn btn-primary" disabled>
                                    Please wait <i className="fa fa-spinner fa-spin"></i>
                                  </button>
                                )}

                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowModal(false);
                                    setError(false);
                                  }}
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="modal-header" data-bs-theme="dark">
                        <h5 className="modal-title" id="liquidityModalLabel">
                          Add Liquidity
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setShowModal(false);
                            setError(false);
                          }}
                        />
                      </div>
                      {/* on body fa-spin and close button */}
                      <div className="modal-body">
                        <div className="container">
                          <div className="mb-3">
                            <p className="text-center">Please select a user to add liquidity</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/*Sell item Modal Ends */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Items;
